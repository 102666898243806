const path = document.querySelector(".path");
const pathItems = path.querySelectorAll(".path__item");

const DELAY = 200;
let isUpdating = false;

const updatePath =  () => {
  const { y } = path.getBoundingClientRect();
  const top = Math.max(0, window.innerHeight - DELAY - y).toFixed(0);
  path.style.setProperty("--line-height", `${top}px`);

  // Update progress for each activated path item
  pathItems.forEach((item) => {
    const currentProgress = parseInt(item.dataset.progress);
    let nextProgress = currentProgress;
    if (item.classList.contains('path__item_activated')) {
      const itemRect = item.getBoundingClientRect();
      const scrollDelta = window.innerHeight - itemRect.y - DELAY;
      const rawProgress = Math.round(scrollDelta * 100 / itemRect.height);
      nextProgress = Math.max(0, Math.min(100, rawProgress));
    } else {
      // Set progress to zero for non-activated items
      nextProgress = 0;
    }

    if (currentProgress !== nextProgress) {
      item.dataset.progress = nextProgress;
    }
  });

  isUpdating = false;
}

const onScroll = () => {
  if (!isUpdating) {
    isUpdating = true;
    requestAnimationFrame(updatePath);
  }
}

const pathItemObserver = new IntersectionObserver(entries => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('path__item_activated')) {
        return;
      }
      entry.target.classList.add('path__item_activated');
    } else {
      if (!entry.target.classList.contains('path__item_activated')) {
        return;
      }
      entry.target.classList.remove('path__item_activated');
    }
  });
});

pathItems.forEach((item) => {
  pathItemObserver.observe(item);
});

const pathObserver = new IntersectionObserver(entries => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      if (entry.target.classList.contains('path_activated')) {
        return;
      }
      entry.target.classList.add('path_activated');
      window.addEventListener('scroll', onScroll, {passive: true});
    } else {
      if (!entry.target.classList.contains('path_activated')) {
        return;
      }
      entry.target.classList.remove('path_activated');
      window.removeEventListener('scroll', onScroll);
    }
  });
});

pathObserver.observe(path);
