import lottie from 'lottie-web';

const ANIMATION_ALL = 'all';
const ANIMATION_DESKTOP = 'desktop';
const ANIMATION_MOBILE = 'mobile';

async function loadAnimation(currentAnimationType, loadAnimationData, config) {
  const { container, breakpoint, loop, autoplay, onLoad } = config;

  if (!container) {
    console.error('Animation container not found');
    return currentAnimationType;
  }

  const nextAnimationType =
    breakpoint === null
      ? ANIMATION_ALL
      : window.innerWidth < breakpoint
      ? ANIMATION_MOBILE
      : ANIMATION_DESKTOP;

  if (currentAnimationType === nextAnimationType) {
    return currentAnimationType;
  }

  let animationData = await loadAnimationData(nextAnimationType);

  if (animationData.data === null) {
    return currentAnimationType;
  }

  container.innerHTML = '';

  if (animationData.type === 'animation') {
    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop,
      autoplay,
      animationData: animationData.data,
    });

    if (typeof onLoad === 'function') {
      onLoad(animation);
    }

    const animationLoading = async () => {
      return new Promise(resolve => {
        animation.addEventListener('DOMLoaded', function handler() {
          resolve();

          animation.removeEventListener('DOMLoaded', handler);
        });
      });
    };

    await animationLoading();
  }
  if (animationData.type === 'image') {
    container.appendChild(animationData.data);
  }

  return nextAnimationType;
}

async function observeAnimation(target, loadAnimationData, config) {
  const { breakpoint } = config;
  let animationType = null; // Can be null, 'desktop', or 'mobile'
  let hasObserver = false;

  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(async entry => {
        if (entry.isIntersecting) {
          animationType = await loadAnimation(
            animationType,
            loadAnimationData,
            config,
          );
          observer.unobserve(entry.target);
          hasObserver = false;
        }
      });
    },
    {
      rootMargin: '1000px',
    },
  );

  if (target) {
    observer.observe(target);
    hasObserver = true;

    if (breakpoint !== null) {
      window.addEventListener('resize', () => {
        if (hasObserver) {
          return;
        }

        const nextAnimationType =
          window.innerWidth < breakpoint ? ANIMATION_MOBILE : ANIMATION_DESKTOP;
        if (animationType === nextAnimationType) {
          return;
        }

        observer.observe(target);
        hasObserver = true;
      });
    }
  }
}

window.addEventListener('load', event => {
  /* Animation Earth Set up */
  const containerEarth = document.getElementById('animation-earth');
  const targetEarth = containerEarth;
  const loadAnimationDataEarth = async nextAnimationType => {
    let data = null;

    try {
      if (nextAnimationType === ANIMATION_MOBILE) {
        data = await import('../animations/animation-earth-mobile.json');
      }
      if (nextAnimationType === ANIMATION_DESKTOP) {
        data = await import('../animations/animation-earth-desktop.json');
      }

      if (data === null) {
        console.error('Animation data for earth is empty');
      }
    } catch (error) {
      console.error('Error loading animation data for earth:', error);
    }

    return {
      type: 'animation',
      data,
    };
  };

  observeAnimation(targetEarth, loadAnimationDataEarth, {
    container: containerEarth,
    breakpoint: 1100,
    loop: true,
    autoplay: true,
  });

  /* Animation Flexy Set up */
  const targetFlexy = document.getElementById('flexy');
  const containerFlexy = document.getElementById('animation-flexy');
  const loadAnimationDataFlexy = async nextAnimationType => {
    let data = null;

    try {
      if (nextAnimationType === ANIMATION_MOBILE) {
        data = await import('../animations/animation-flexy-mobile.json');
      }
      if (nextAnimationType === ANIMATION_DESKTOP) {
        data = await import('../animations/animation-flexy-desktop.json');
      }

      if (data === null) {
        console.error('Animation data for flexy is empty');
      }
    } catch (error) {
      console.error('Error loading animation data for flexy:', error);
    }

    return {
      type: 'animation',
      data,
    };
  };

  observeAnimation(targetFlexy, loadAnimationDataFlexy, {
    container: containerFlexy,
    breakpoint: 600,
    loop: true,
    autoplay: true,
  });

  /* Animation Muffins Path Set up */
  const targetPath = document.querySelector('.path');
  const pathItems = targetPath.querySelectorAll('.path__item');

  pathItems.forEach(pathItem => {
    const containerPath = pathItem.querySelector('.path__animation');
    const id = parseInt(pathItem.dataset.id);
    if (id === NaN) {
      return;
    }

    const loadAnimationDataPath = async nextAnimationType => {
      let data = null;

      if (nextAnimationType === ANIMATION_ALL) {
        try {
          switch (id) {
            case 1:
              data = await import('../animations/animation-path-1.json');
              break;
            case 2:
              data = await import('../animations/animation-path-2.json');
              break;
            case 3:
              data = await import('../animations/animation-path-3.json');
              break;
            case 4:
              data = await import('../animations/animation-path-4.json');
              break;
            case 5:
              data = await import('../animations/animation-path-5.json');
              break;
          }
        } catch (error) {
          console.error(`Error loading animation data for path ${id}:`, error);
        }
      }

      return {
        type: 'animation',
        data,
      };
    };

    observeAnimation(targetPath, loadAnimationDataPath, {
      container: containerPath,
      breakpoint: null,
      loop: false,
      autoplay: false,
      onLoad: animation => {
        const frames = animation.getDuration(true);
        const pathItemObserver = new MutationObserver(mutations => {
          mutations.forEach(mutation => {
            if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'data-progress'
            ) {
              const progress = parseInt(mutation.target.dataset.progress);
              const frame = Math.round((frames * progress) / 100);
              animation.goToAndStop(frame, true);
            }
          });
        });

        pathItemObserver.observe(pathItem, { attributes: true });
      },
    });
  });

  /* Animation Hero Set up */
  const targetHero = document.getElementById('page');
  const containerHero = document.getElementById('animation-hero');
  const loadAnimationDataHero = async nextAnimationType => {
    let data = null;

    try {
      if (nextAnimationType === ANIMATION_MOBILE) {
        data = await import('../animations/animation-hero-mobile.json');
      }
      if (nextAnimationType === ANIMATION_DESKTOP) {
        data = await import('../animations/animation-hero-desktop.json');
      }

      if (data === null) {
        console.error('Animation data for hero is empty');
      }
    } catch (error) {
      console.error('Error loading animation data for hero:', error);
    }

    return {
      type: 'animation',
      data,
    };
  };

  observeAnimation(targetHero, loadAnimationDataHero, {
    container: containerHero,
    breakpoint: 450,
    loop: false,
    autoplay: true,
  });
});
